
import Vue, { PropType } from 'vue';
import { UserCardColumnNames } from '@/constants/userForRateColumns';
import {
  PostV1CouplesIam,
  V1EntitiesReviewsCouple,
  V1EntitiesReviewsExpertList,
  PostV1Invitees,
} from '@/services/api/tsxass';
import { joinExpertGroupsWithCouples, GroupCouples } from '@/helpers/joinExpertGroupsWithCouples';
import { UserId } from '@/services/api/common/types';
import ReviewExpertGroup from '@/components/assessmentChecklist/ReviewExperts/ReviewExpertGroup.vue';
import ExpertGroupUserAddingDialog from '@/components/userAddingDialogs/ExpertGroupUserAddingDialog.vue';
import { UserCardColumn } from '@/components/common/UserGroupTable/types';

interface CouplesData {
  groups: V1EntitiesReviewsExpertList[];
  couples: V1EntitiesReviewsCouple[];
}

export default Vue.extend({
  name: 'ReviewSingleExpertGroupList',

  components: {
    ReviewExpertGroup,
    ExpertGroupUserAddingDialog,
  },

  props: {
    couplesData: {
      type: Object as PropType<CouplesData>,
      required: true,
    },
    columns: {
      type: Array as PropType<UserCardColumn[]>,
      default(): UserCardColumn[] {
        return [
          {
            name: UserCardColumnNames.EMPLOYEE,
            title: this.$t('userForRate.employee') as string,
            width: '6fr',
          },
          {
            name: UserCardColumnNames.TEAM,
            title: this.$t('userForRate.team') as string,
            width: '5fr',
          },
          {
            name: UserCardColumnNames.ACTION,
            title: '',
            width: '5fr',
          },
        ];
      },
    },
    surveyId: {
      type: Number,
      required: true,
    },
    couplesRequestWithoutParam: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },

  data() {
    return {
      iam: PostV1CouplesIam.SURVEYEE,
      selectedGroupId: null as number | null,
      isAddingToGroupDialogShow: false,
    };
  },

  computed: {
    groupsWithCouples(): GroupCouples[] {
      if (!this.couplesData) {
        return [];
      }

      return joinExpertGroupsWithCouples(this.couplesData);
    },
    surveyeeId(): string {
      return this.$route.params.surveyeeId;
    },
    canAddExternalExperts(): boolean {
      const selectedGroup = this.couplesData?.groups?.find((list) => list.id === this.selectedGroupId);
      return Boolean(selectedGroup?.canAddExternalExperts);
    },
  },

  methods: {
    addEmployee(groupId: number) {
      this.selectedGroupId = groupId;
      this.isAddingToGroupDialogShow = true;
    },
    async addUsersToGroup(userIds: UserId[]) {
      if (!this.selectedGroupId) {
        return;
      }

      this.$emit('add-users-to-group', {
        selectedGroupId: this.selectedGroupId,
        userIds,
      });

      this.isAddingToGroupDialogShow = false;
    },
    async addExternalExperts(users: PostV1Invitees[]) {
      this.$emit('add-external-experts', users);
      this.isAddingToGroupDialogShow = false;
    },
  },
});
