
import Vue, { PropType } from 'vue';
import { UserCardColumnNames } from '@/constants/userForRateColumns';
import SectionTitle from '@/components/common/SectionTitle.vue';
import AddExternalExpertsBtn from '@/components/assessmentChecklist/AddExternalExpertsBtn.vue';

interface UserCardColumn {
  name: string;
  title?: string;
  width?: number | string;
}

export default Vue.extend({
  name: 'UserExpertTable',

  components: {
    SectionTitle,
    AddExternalExpertsBtn,
  },

  props: {
    name: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    showAddUserButton: {
      type: Boolean,
      default: false,
    },
    canAddExternalExperts: {
      type: Boolean,
      default: false,
    },
    groupId: {
      type: Number,
      default: -1,
    },
    userId: {
      type: String,
      default: undefined,
    },
    columns: {
      type: Array as PropType<UserCardColumn[]>,
      default(): UserCardColumn[] {
        return [
          {
            name: UserCardColumnNames.EMPLOYEE,
            title: this.$t('userForRate.employee') as string,
            width: '6fr',
          },
          {
            name: UserCardColumnNames.TEAM,
            title: this.$t('userForRate.team') as string,
            width: '5fr',
          },
        ];
      },
    },
    addUserButtonLabel: {
      type: String,
      default(): string {
        return this.$t('userForRate.addEmployeeForAssessment') as string;
      },
    },
  },

  computed: {
    gridTemplateColumns(): string {
      const res = this.columns.reduce((acc: string[], col: UserCardColumn) => {
        acc.push('width' in col ? String(col.width) : 'auto');
        return acc;
      }, []);
      return res.join(' ');
    },
    cssVars(): Record<string, any> {
      return {
        '--grid-template-columns': this.gridTemplateColumns,
      };
    },
  },
});
