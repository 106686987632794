
import Vue, { PropType } from 'vue';
import { UserCardColumnNames } from '@/constants/userForRateColumns';
import { V1EntitiesCouplesPublicExpertIndexCouple } from '@/services/api/tsxass';
import { UserCardColumn } from '@/components/common/UserGroupTable/types';
import UserCard from '@/components/common/UserGroupTable/UserCard.vue';
import UserExpertTable from '@/components/assessmentChecklist/ReviewExperts/UserExpertTable.vue';

export default Vue.extend({
  name: 'ReviewExpertGroup',

  components: {
    UserCard,
    UserExpertTable,
  },

  props: {
    name: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    items: {
      type: Array as PropType<V1EntitiesCouplesPublicExpertIndexCouple[]>,
      default: () => [],
    },
    canAddExternalExperts: {
      type: Boolean,
      default: false,
    },
    groupId: {
      type: Number,
      default: -1,
    },
    userId: {
      type: String,
      default: undefined,
    },
    columns: {
      type: Array as PropType<UserCardColumn[]>,
      default(): UserCardColumn[] {
        return [
          {
            name: UserCardColumnNames.EMPLOYEE,
            title: this.$t('userForRate.employee') as string,
            width: '6fr',
          },
          {
            name: UserCardColumnNames.TEAM,
            title: this.$t('userForRate.team') as string,
            width: '5fr',
          },
          {
            name: UserCardColumnNames.ACTION,
            title: '',
            width: '5fr',
          },
        ];
      },
    },
  },

  data() {
    return {
      UserCardColumnNames,
    };
  },
});
